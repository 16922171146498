





























import { Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import SettingsBoxItem from '@/views/settings/SettingsBoxItem.vue';
import CompanySettingsBoxItem from '@/views/settings/CompanySettingsBoxItem.vue';
import ChangeEmailModalComponent from '@/components/settings/account/ChangeEmailModalComponent.vue';
import ChangePasswordModalComponent from '@/components/settings/account/ChangePasswordModalComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

const communityUserStore = namespace('CommunityUserStore');

@Component({
  components: {
    ChangeEmailModalComponent,
    ChangePasswordModalComponent,
    SettingsBoxItem,
    CompanySettingsBoxItem,
  },
})
export default class AccountSection extends BreakpointWrapper {
  @Getter
  protected readonly authUser!: CommunityUser;

  @communityUserStore.Action
  private loadEmailForAuthUser!: () => Promise<void>;

  private get haveCompanyRole(): boolean {
    if (this.authUser.companyRoles) {
      return this.authUser?.companyRoles.length > 0;
    }
    return false;
  }

  created(): void {
    this.loadEmailForAuthUser();
  }

  onEditEmail(): void {
    this.$bvModal.show('change-email-modal');
  }

  onEditPassword(): void {
    this.$bvModal.show('change-password-modal');
  }

  private onEmailChanged(email: string): void {
    this.$emit('reset-email-success', email);
  }
}
