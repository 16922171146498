



























































import { Component, Prop } from 'vue-property-decorator';
import { BContainer } from 'bootstrap-vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { Getter, namespace } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import CompanyRoleEnum from '@/utils/enums/CompanyRoleEnum';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';

const notificationStore = namespace('NotificationStore');
const companyUserRoleStore = namespace('CompanyUserRoleStore');
const toastStore = namespace('ToastStore');
const communityUserStore = namespace('CommunityUserStore');

@Component({
  components: {
    ButtonComponent,
    BContainer,
    ConfirmModal,
    AvatarSoloWidget,
  },
})
export default class CompanySettingsBoxItem extends BreakpointWrapper {
  @Prop({ required: true, default: '' })
  title!: string;

  @Prop({ required: false, default: '' })
  subtitle!: string;

  @Getter
  protected readonly authUser!: CommunityUser;

  @notificationStore.Getter('fetchNotifications')
  private notifications!: Notification[];

  @companyUserRoleStore.Action
  private updateCompanyUserRoleState!: (payload: {
    uid: string; state: CompanyUserRoleStatusType;
  }) => Promise<CompanyUserRole>

  @communityUserStore.Action
  private updateUserProfile!: (payload: Partial<CommunityUser>) => void;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private selectedCompanyRole: CompanyUserRole | undefined;

  private FileResourceHelper = FileResourceHelper;

  private get authUserCompanies(): CompanyUserRole[] | undefined {
    return this.authUser?.companyRoles;
  }

  private showToast(type: ToastActionType, companyName: string): void {
    this.addNewAction({ type, item: companyName });
  }

  private leaveCompany(companyRole: CompanyUserRole): void {
    if (companyRole.role.code && !this.isCompanyOwner(companyRole.role.code)) {
      this.selectedCompanyRole = companyRole;
      this.$bvModal.show('leave-modal-main');
    } else {
      this.$bvModal.show('cannot-leave-modal-main');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private isCompanyOwner(role: string): boolean {
    return role === CompanyRoleEnum.OWNER;
  }

  private leaveConfirmationModal(): void {
    const companyRole = this.selectedCompanyRole as CompanyUserRole;
    const roleUid = companyRole
      ? companyRole.uid.toString()
      : '';
    this.updateCompanyUserRoleState({
      uid: roleUid,
      state: companyRole.state === CompanyUserRoleStatusType.ACCEPTED
        ? CompanyUserRoleStatusType.CANCELLED
        : CompanyUserRoleStatusType.INVITE_CANCELLED,
    }).then((response) => {
      if (response) {
        if (this.authUser && this.authUser.companyRoles) {
          // eslint-disable-next-line no-unused-expressions
          this.authUser?.companyRoles.forEach((c, index) => {
            if (c.uid === response.uid && this.authUser.companyRoles) {
              // eslint-disable-next-line no-unused-expressions
              this.authUser?.companyRoles.splice(index, 1);
            }
          });
          if (this.authUser.employerName === companyRole.company?.name) {
            const employerName = (this.authUser.companyRoles) ? this.authUser.companyRoles[0].company?.name : '';
            this.updateUserProfile({ employerName });
          }
        }
        this.showToast(ToastActionType.LEFT_COMPANY, this.selectedCompanyRole?.company?.name as string);
      }
    });
  }
}
